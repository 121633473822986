import React from "react";
import { graphql } from "gatsby";
import { SliceZone } from "@prismicio/react";
import Seo from "../components/meta/Seo";
import PageBanner from "../components/elements/PageBanner";
import "../styles/pages/about.scss";
import { constructActiveDoc } from "../utilities/helpers";

import Layout from "../components/common/Layout";
import components from "../components/slices/index";

const About = ({ data }) => {
  if (!data) return null;
  const { page } = data;

  return (
    <div className={data.page.type}>
      <Seo page={page} />
      <Layout activeDocMeta={constructActiveDoc(page)}>
        <PageBanner
          title={page.data.title.text}
          bgImage={page.data.banner_image}
          bgImageMobile={page.data.image_mobile}
          page={page}
        />
        <SliceZone components={components} slices={page.data.body} />
      </Layout>
    </div>
  );
};

export const query = graphql`
  query aboutQuery($lang: String, $id: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicAbout(lang: { eq: $lang }, id: { eq: $id }) {
      ...prismicAboutFragment
    }
  }
`;

export default React.memo(About);
